@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

:root {
  --color-1: #3a5533; //primary
  --color-2: #f58a21; //secondary
  --color-3: #643405;
  --color-4: #3d3d3d; //footer
  --color-5: #66e546; //star and arrows
}
.bg-1 {
  background: var(--color-1);
}
.bg-2 {
  background: var(--color-2);
}
.bg-3 {
  background: var(--color-3);
}
.bg-4 {
  background: var(--color-4);
}
.bg-5 {
  background: var(--color-5);
}
.textcolor-primary {
  color: var(--color-1) !important;
}
.textcolor-secondary {
  color: var(--color-2) !important;
}
.textcolor-3 {
  color: var(--color-3);
}
.textcolor-4 {
  color: var(--color-4);
}
.textcolor-5 {
  color: var(--color-5);
}

.rotate180 {
  transform: rotateX(180deg) rotateY(180deg);
}
body {
  font-family: "Rubik", sans-serif !important;
  margin-top: 100px !important;
}

a {
  text-decoration: none;
}

nav {
  padding: 0 1rem !important;

  .collapsing {
    height: 100% !important;
  }
}
.nav-item {
  padding: 0 1rem;
}
.nav-contact-cta {
  padding-left: 3rem;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--color-2) !important;
}
.button-primary {
  background: var(--color-2) !important;
  color: white !important;
  border-radius: 0 !important;
}
.button-primary-outline {
  border: 3px solid var(--color-2) !important;
  background: white !important;
  border-radius: 0px !important;
}
.button-primary-outline:hover {
  border: 3px solid var(--color-2) !important;
  border-radius: 0px !important;
  color: white !important;
  background: var(--color-2) !important;
}
.hero {
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 50vw;
  color: white;
  position: relative;
  .hero-title-top {
    font-size: 0.8em;
    font-weight: 500;
  }
  .hero-title {
    font-size: 4.11vw;
    font-weight: 900;
    line-height: 4.11vw;
    color: var(--color-2);
    span {
      color: white;
    }
  }
  .hero-cta {
    color: #273044 !important;
  }
  // .hero-bottom {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  // }
}
.philosophy {
  background: var(--color-1);
  color: white;
  position: relative;
  .phil-overlay {
    position: absolute;
    top: 42vw;
  }
  .phil {
    padding: 100px 1rem 120px 1rem;
    .phil-left {
      .phil-left-inner-top {
        img {
          height: 40vw;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .phil-right {
      padding-left: 5vw;
      .phil-right-title {
        position: relative;
        img {
          height: 30px;
        }
        .phil-right-title-text {
          display: flex;
          align-items: center;
          height: 100%;
          position: absolute;
          top: 0;
          left: 20px;
          z-index: 0;
          font-size: 1rem;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      .phil-body {
        padding: 2rem 0;
      }
      .heading {
        text-transform: uppercase;
        font-weight: 800;
        margin: 2rem 0 1rem 0;
      }
      .phil-cta {
        .button-primary-outline {
          color: white;
        }
      }
    }
  }
}
.packages,
.t-packages {
  background: url("/public/assets/img/package-bg.png");
  color: white;
  h2 {
    font-weight: 900 !important;
  }
  .slick-slider {
    .slick-prev:before,
    .slick-next:before {
      font-family: "slick";
      font-size: 30px !important;
      color: black !important;
    }
    .slick-next {
      right: -20px !important;
    }
  }
  .packages-item {
    padding: 2rem;
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 25px;
    }
    .packages-item-inner {
      border-radius: 25px;
      position: relative;
      height: 400px;
      // width: 380px;

      .packages-item-inner-overlay {
        position: absolute;
        top: 0;
        border-radius: 25px;
        padding-bottom: 2rem;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          4deg,
          rgba(0, 0, 0, 0.5) 32.87%,
          rgba(255, 255, 255, 0) 99.81%
        );
        h4 {
          font-size: 20px;
          font-weight: 800;
          padding: 1rem 0;
        }
        .icon {
          img {
            height: 30px;
            width: 30px;
            border-radius: 0;
            object-fit: contain;
          }
          span {
            margin: auto;
            padding: 0 0.5rem;
          }
        }
        a {
          display: none;
          color: white;
        }
      }
      .packages-item-inner-overlay:hover {
        .icon {
          display: none !important;
        }
        a {
          display: block;
        }
      }
    }
  }
  .pagination {
    .page-item {
      --bs-pagination-color: var(--color-2) !important;
      --bs-pagination-active-bg: var(--color-2) !important;
      --bs-pagination-active-border-color: var(--color-2) !important;
      .page-link:hover {
        color: #484848 !important;
      }
      .page-link:focus {
        color: var(--color-2) !important;
        box-shadow: none !important;
      }
    }
  }
}
.why {
  .row {
    padding: 150px 5rem;
  }
  background: var(--color-4);
  color: white;
  h1 {
    font-size: 4.04vw;
    line-height: 3.5vw;
  }
  .why-overlay-top {
    img {
      width: 100%;
    }
  }
  .why-overlay-bottom {
    img {
      width: 100%;
      transform: rotateX(180deg) rotateY(180deg);
    }
  }
  .why-img {
    .why-img-inner {
      img {
        width: 100%;
        border-radius: 25px;
        height: 26vw;
        object-fit: cover;
      }
    }
  }
  .why-text {
    p {
      margin-bottom: 4px;
    }
    padding: 1em;
    .title {
      font-weight: 900;
    }
    ul {
      padding-top: 2rem;
      list-style-image: url("/public/assets/img/arrow.svg");
      li {
        padding: 0.3rem 0;
        padding-left: 1.5rem;

        font-size: 1vw;
      }
    }
    a {
      color: white;
      margin-top: 2rem;
    }
  }
}
.explore {
  background: url("/public/assets/img/explore-bg.png");
  // padding: 1rem;
  // padding-bottom: 3rem;
  .btn {
    border-radius: 0 !important;
    border: 2px solid gray;
  }
  .btn:hover {
    color: white !important;
  }
  strong {
    color: var(--color-2);
    font-weight: normal;
  }
}

.testimonials {
  // background: url("/public/assets/img/bg-testimonials.png") no-repeat;
  background: url("/public/assets/img/bg-hills1.png") no-repeat;
  background-size: 100% auto;
  h2 {
    font-weight: 900;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
}
.latest-trip {
  background: var(--color-3);
  color: white;
  img {
    width: 100%;
    height: auto;
  }
  .latest-trip-left {
    .latest-trip-left-img {
      position: relative;
      .latest-trip-left-img-overlay {
        height: 100%;
        width: 100%;
        background: rgba(61, 61, 61, 0.3);
        position: absolute;
        bottom: 0%;
        left: 0%;
        i {
          color: #cd201f;
        }
      }
    }
  }
  .latest-trip-right {
    .slick-arrow {
      display: none !important;
    }
  }
}
.banner {
  width: 100%;
  height: 24vw;
  min-height: 300px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: right;
  .form-control {
    border-radius: 0 !important;
  }
  .banner-text {
    font-weight: 900;
  }
}
footer {
  background: var(--color-4);
  color: #c2c2c2;
  .title {
    color: var(--color-2);
    text-transform: uppercase;
  }
  i {
    color: var(--color-2);
    padding: 0 0.5rem;
  }
  a {
    color: #c2c2c2 !important;
    text-decoration: none;
  }
  ul {
    padding: 0 !important;
    li {
      list-style-type: none;
    }
  }
  .footer-top {
    padding-top: 2rem;
  }
  .footer-below {
    padding: 0.5rem 0;
  }
  .associated-img {
    padding: 1rem 0;
    img {
      width: 100%;
      border-radius: 50%;
      margin: 0 0.5rem;
    }
  }
  .we-accept {
    padding: 0.5rem 0;
    img {
      padding: 0 0.5rem;
    }
  }
}

// inner pages start

.top-banner {
  height: 25vw;
  background-repeat: no-repeat !important;
  background-position: 5% 45%;
  background-size: cover;
  color: white;
}

/* Contact Page */

.contact-us {
  background: linear-gradient(180deg, #fff 40%, #f9f9f9 70%, #f9f9f9 100%);
  .contact-us-left {
    padding: 2rem;
    p {
      font-size: 15px;
      font-weight: 400;
      color: #484848;
      line-height: 22px; /* 146.667% */
      letter-spacing: 1.35px;
    }
    .contact-us-left-below {
      i {
        font-size: 3rem;
        color: var(--color-2);
      }
      p {
        border-left: 2px solid var(--color-2);
        padding: 2rem 1rem;
      }
    }
  }
  .contact-us-right {
    height: fit-content;
    background: var(--color-2);
    border-radius: 0.5rem;
    padding: 2rem;
    h3 {
      color: white;
    }
  }
  // .banner {
  //   width: 100%;
  //   height: 17vw;
  //   min-height: 250px;
  //   background-position: 50% 70%;
  // }
}

/* About Page */

.about {
  .about-left {
    overflow: hidden;
    h2 {
      font-weight: 900;
      font-size: 45px;
      padding-bottom: 4rem;
      margin: 0;
    }
    img {
      border-radius: 25px;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
  .about-right {
    padding-top: 1rem;
    padding-left: 4rem;
    p {
      color: #484848;
      text-align: justify;
      line-height: 22px;
    }
    .about-right-below {
      padding-top: 3rem;
      .col-md-5 {
        padding-right: 3rem !important;
      }
      img {
        width: 100%;
        height: 25vw;
        object-fit: cover;
        border-radius: 25px;
      }
    }
  }
  .about-team {
    color: #484848;
    padding: 2rem 0;
    h1 {
      color: var(--color-2);
      font-weight: 900;
      padding-bottom: 2rem;
    }
    .modal {
      .modal-dialog {
        max-width: 60% !important;
      }
    }
    .about-team-item {
      width: 100%;
      border-radius: 16px;
      text-align: center;
      transition: 0.1s;
      background: none;
      padding: 0;
      // outline: 6px solid white !important;
      outline: none;
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }
      h5 {
        color: #484848;
        padding: 1rem 0.5rem;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0;
      }
      p {
        font-size: 12px;
        color: var(--color-2);
        text-transform: capitalize;
      }
      .about-team-item-text {
        background: white;
        border-radius: 0 0 16px 16px;
      }
    }
    .about-team-item:hover {
      background: var(--color-2) !important;
      outline: 6px solid var(--color-2) !important;
      padding: 0;
      h5 {
        color: white;
      }
      p {
        color: #484848;
      }
      .about-team-item-text {
        background: var(--color-2);
      }
    }
  }
  .why-more {
    .why-text {
      ul {
        list-style-image: url("/public/assets/img/arrow.svg");
        padding-left: 4vw;
        li {
          strong {
            color: var(--color-2);
            font-weight: 900;
          }
        }
      }
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin: 0;
        padding-bottom: 4rem;
        color: var(--color-2);
      }
    }
  }
}

// Main inner page

.main-inner-page {
  .main-inner-page-top {
    padding: 2rem 0 4rem 0;
    .main-inner-page-top-item {
      padding: 8px 0;
      color: #484848;
      .main-inner-page-top-item-img {
        img {
          // width: 5vw;
          width: 60px;
        }
      }
    }
    .main-inner-page-top-item:nth-child(1) {
      border-left: none !important;
      border-right: none !important;
    }
    .main-inner-page-top-item:nth-child(-n + 4) {
      border-bottom: 2px solid #e5e5e5;
      border-left: 2px solid #e5e5e5;
    }
    .main-inner-page-top-item:nth-child(5) {
      border: none !important;
    }
    .main-inner-page-top-item:nth-last-child(-n + 4) {
      border-left: 2px solid #e5e5e5;
    }
  }
  .main-inner-page-right {
    height: fit-content;
    .main-inner-page-right-inner {
      border-radius: 1rem;
      padding: 1.5rem;
      background: #484848;
      .form-select {
        color: var(--bs-secondary-color);
      }
      h6 {
        color: white;
      }
      p {
        color: white;
        font-size: 13px;
        span {
          padding: 0 0.5rem;
          color: var(--color-2);
        }
      }
      button {
        color: var(--color-2);
      }
      button:hover {
        color: white;
      }
    }
  }
  .main-inner-page-left {
    .slick-slider {
      .slick-next {
        right: -2px;
        top: 49.5%;
        width: 50px;
        height: 90%;
      }
      .slick-prev {
        left: 0px;
        width: 50px;
        height: 90% !important;
        top: 49.5%;
        z-index: 1;
      }
      .slick-prev:hover,
      .slick-prev:focus,
      .slick-next:hover,
      .slick-next:focus {
        color: transparent;
        outline: none;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    .main-inner-page-left-img-top {
      img {
        object-fit: contain !important;
      }
    }

    .main-inner-page-left-img-below {
      .slick-slider {
        height: 100px !important;
        overflow: hidden;
        button {
          display: none !important;
        }
      }
      img {
        object-fit: contain !important;
      }
    }
  }
  .main-inner-page-bottom {
    p {
      color: #484848;
      span {
        color: var(--color-2);
        padding: 0.25rem;
        border-radius: 8px;
        font-weight: 700;
      }
    }
    .nav-link {
      font-weight: 600;
      font-size: 20px;
      line-height: 0px;
      color: #484848 !important;
    }
    .nav .nav-link.active {
      color: white !important;
      background: var(--color-2);
      // color: var(--color-2) !important;
      // border-bottom-color: currentcolor;
    }
    .main-inner-page-bottom-img-top {
      img {
        width: 100%;
      }
    }
    .main-inner-page-bottom-img-bottom {
      img {
        width: 100%;
        transform: rotateX(180deg) rotateY(180deg);
      }
    }
  }
  .client-review {
    padding: 2rem;

    .rating {
      input[type="radio"] {
        display: none;
      }
    }
    // .slider {
    //   appearance: none;
    //   height: 10px;
    //   border-radius: 5px;
    //   background: var(--color-2);
    //   outline: none;
    //   opacity: 0.7;
    //   -webkit-transition: 0.2s;
    //   transition: opacity 0.2s;
    // }

    // .slider:hover {
    //   opacity: 1;
    // }

    // .slider::-webkit-slider-thumb {
    //   -webkit-appearance: none;
    //   appearance: none;
    //   width: 20px;
    //   height: 20px;
    //   border-radius: 50%;
    //   background: black;
    //   cursor: pointer;
    // }

    // .slider::-moz-range-thumb {
    //   width: 20px;
    //   height: 20px;
    //   border-radius: 50%;
    //   background: black;
    //   cursor: pointer;
    // }
    h6 {
      color: var(--color-2);
      padding: 0;
    }
    p {
      padding: 0;
    }
  }
  .related-trip {
    background: url("/public/assets/img/related-trip-bg.png");
    .related-trip-top {
      img {
        width: 100%;
      }
    }
    .textcolor-primary {
      font-weight: 500;
    }
    h3 {
      color: var(--color-2);
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 4rem;
    }
    .related-trip-img-item {
      color: #484848;
      padding: 0 1rem;
      h5 {
        margin-top: 1rem;
        font-weight: 800;
        text-transform: uppercase;
      }
      h6 {
        color: var(--color-5);
        font-size: 0.8rem;
      }
      a {
        font-weight: 800;
        // font-size: 0.6rem;
      }
      img {
        width: 100%;
        border-radius: 25px;
        filter: brightness(0.8);
        height: 200px;
        object-fit: cover;
      }
    }
  }
}

//Main inner page end

.gallery {
  .slick-arrow {
    display: none !important;
  }
  .mason-item {
    position: relative;
    .gallery-item-inner {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
    .a {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  .mason-item:hover {
    .gallery-item-inner {
      opacity: 1;
    }
  }
  .mason-item:hover {
    .a {
      opacity: 1;
    }
  }
}

.gallery-listing {
  a {
    img {
      width: 33.33%;
      object-fit: cover;
      height: 250px;
    }
  }
}

.faq {
  .accordion {
    border-radius: 6px;
    border: 2px solid #f58b2176 !important;
    .accordion-button {
      border-bottom: 2px solid #f58b2176 !important;
      background-color: #f58b2176;
      box-shadow: none;
    }
    .accordion-button:focus {
      box-shadow: red !important;
    }
    .accordion-button:not(.collapsed) {
      color: white !important;
      background-color: var(--color-2);
    }
  }
}

// responsive

@media screen and (min-width: 280px) and (max-width: 575px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  body {
    --bs-body-font-size: 0.8rem !important;
  }
  .hero {
    height: 100vw;
    .hero-cta {
      padding: 0.5rem !important;
      color: #273044 !important;
    }
  }
  .philosophy {
    .phil {
      padding: 50px 1rem 60px 1rem;
      .phil-left {
        .phil-left-inner-top img {
          height: 80vw;
        }
      }
      .phil-right {
        padding: 1rem;
        padding-left: 0rem;
        .heading {
          margin: 1rem 0;
          text-align: center;
        }
        .phil-body {
          padding: 1rem 0 2rem 0;
        }
      }
    }
  }
  .packages,
  .t-packages {
    .packages-item {
      padding: 2rem;
      .packages-item-inner {
        height: 50vw !important;
        .packages-item-inner-overlay {
          h4 {
            font-size: 16px;
            font-weight: 800;
            padding: 1rem 0;
          }
        }
      }
    }
  }

  .why {
    .why-img {
      .why-img-inner {
        img {
          width: 100%;
          border-radius: 25px;
          height: 80vw;
          object-fit: cover;
        }
      }
    }
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }

    .row {
      padding: 1rem;
      .why-text {
        .title {
          text-align: center;
        }
        ul li {
          padding: 0 1rem;
          font-size: 12px;
        }
      }
    }
  }
  footer .associated-img img {
    margin: 0;
  }

  .about {
    .about-left {
      h2 {
        font-size: 2rem;
        padding-bottom: 2rem;
        margin: 0;
      }
    }
    .about-right {
      padding-top: 0;
      padding-left: 0;
      padding: 1rem 0;
      .about-right-below {
        padding-top: 0;
        padding: 1rem;
        img {
          width: 100%;
          height: 50vw;
          object-fit: cover;
          border-radius: 25px;
        }
      }
    }
    .about-team {
      padding: 0;
      h1 {
        padding-bottom: 1rem;
      }
    }
  }
  .contact-us {
    .contact-us-left {
      padding: 0.5rem;
      .contact-us-left-below {
        p {
          font-size: 12px !important;
          padding: 0.5rem !important;
        }
      }
    }
  }
  .main-inner-page {
    .main-inner-page-top {
      padding: 1rem 0 2rem 0;
      .main-inner-page-top-item {
        border: none !important;
        .main-inner-page-top-item-img {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-right {
      height: -moz-fit-content;
      height: fit-content;
      padding: 0;
    }
    .main-inner-page-left {
      padding: 2rem 0;
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 0.8rem;
        }
      }
    }
  }
  .gallery-listing {
    a {
      img {
        width: 50%;
        object-fit: cover;
        height: 100px;
      }
    }
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  .philosophy {
    .phil {
      padding: 50px 1rem 60px 1rem;
      .phil-left {
        .phil-left-inner-top img {
          height: 65vw;
        }
      }
      .phil-right {
        padding: 1rem;
        padding-left: 0rem;
        .heading {
          margin: 1rem 0;
          text-align: center;
        }
        .phil-body {
          padding: 1rem 0 2rem 0;
        }
      }
    }
  }
  .packages,
  .t-packages {
    .packages-item {
      padding: 2rem;
      .packages-item-inner {
        height: 50vw !important;
        .packages-item-inner-overlay {
          h4 {
            font-size: 16px;
            font-weight: 800;
            padding: 1rem 0;
          }
        }
      }
    }
  }

  .why {
    .why-img {
      .why-img-inner {
        img {
          width: 100%;
          border-radius: 25px;
          height: 60vw;
          object-fit: cover;
        }
      }
    }
    h1 {
      font-size: 2rem;
    }
    .row {
      padding: 2rem 1rem;
      .why-text {
        .title {
          text-align: center;
        }
        ul li {
          padding: 0 1rem;
          font-size: 12px;
        }
      }
    }
  }
  footer .associated-img img {
    margin: 0;
  }

  .about {
    .about-left {
      h2 {
        font-size: 2rem;
        padding-bottom: 2rem;
        margin: 0;
      }
    }
    .about-right {
      padding-top: 0;
      padding-left: 0;
      padding: 1rem 0;
      .about-right-below {
        padding-top: 0;
        padding: 1rem;
        img {
          width: 100%;
          height: 50vw;
          object-fit: cover;
          border-radius: 25px;
        }
      }
    }
    .about-team {
      padding: 0;
      h1 {
        padding-bottom: 1rem;
      }
    }
  }

  .main-inner-page {
    .main-inner-page-top {
      padding: 1rem 0 2rem 0;
      .main-inner-page-top-item {
        border: none !important;
        .main-inner-page-top-item-img {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-left {
      padding: 2rem 1rem;
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
  .gallery-listing {
    a {
      img {
        width: 50%;
        object-fit: cover;
        height: 150px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .nav-contact-cta {
    padding-left: 0 !important;
  }
  .philosophy {
    .phil {
      padding: 10vw 1rem 12vw 1rem;
      .phil-left {
        .phil-left-inner-top img {
          height: 45vw;
        }
      }
      .phil-right {
        .heading {
          margin: 1rem 0;
        }
        .phil-body {
          padding: 1rem 0 2rem 0;
        }
      }
    }
    .phil-overlay {
      top: 50vw;
    }
  }

  .why {
    .why-img {
      .why-img-inner {
        img {
          width: 100%;
          border-radius: 25px;
          height: 60vw;
          object-fit: cover;
        }
      }
    }
    .row {
      padding: 100px 5rem;
      .why-text {
        padding: 2em;
        padding-bottom: 0;
        .title {
          text-align: center;
        }
        ul li {
          padding: 0 1rem;
          font-size: 1em;
        }
      }
    }
  }
  footer .associated-img img {
    margin: 0;
  }

  .about {
    .about-left {
      h2 {
        font-size: 2rem;
        padding-bottom: 2rem;
        margin: 0;
      }
    }
    .about-right {
      padding-top: 0;
      padding-left: 0;
      padding: 1rem 0;
      .about-right-below {
        padding-top: 0;
        padding: 1rem;
        img {
          width: 100%;
          height: 50vw;
          object-fit: cover;
          border-radius: 25px;
        }
      }
    }
    .about-team {
      padding: 0;
      h1 {
        padding-bottom: 1rem;
      }
    }
  }

  .main-inner-page {
    .main-inner-page-top {
      padding: 1rem 0 2rem 0;
      .main-inner-page-top-item {
        border: none !important;
        .main-inner-page-top-item-img {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-left {
      padding: 2rem 1rem;
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 1rem;
        }
      }
    }
  }
  .gallery-listing {
    a {
      img {
        width: 50%;
        object-fit: cover;
        height: 200px;
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .navbar-collapse {
    padding-bottom: 1rem;
  }
  .philosophy {
    .phil-overlay {
      top: 44vw;
    }
  }

  .why {
    .why-img {
      .why-img-inner {
        img {
          width: 100%;
          border-radius: 25px;
          height: 35vw;
          object-fit: cover;
        }
      }
    }
    .row {
      padding: 120px 4rem;
      .why-text {
        padding: 2em;
        padding-bottom: 0;
        a {
          color: white;
          margin-top: 1rem;
        }
        ul li {
          padding-left: 2rem;
          font-size: 1em;
        }
      }
    }
  }
  footer .associated-img img {
    margin: 0;
  }

  .about {
    .about-right {
      .about-right-below {
        img {
          width: 100%;
          height: 225px;
        }
      }
    }
  }
  .main-inner-page {
    .main-inner-page-top {
      .main-inner-page-top-item {
        .main-inner-page-top-item-img {
          img {
            width: 100px;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) and (max-width: 1999.98px) {
  body {
    font-size: 20px !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }

  .philosophy {
    .container {
      width: calc(100vw - 2rem) !important;
      max-width: 95% !important;
    }
    .phil-overlay {
      top: 42vw;
    }
    .phil {
      padding: 5vw 1rem;
      .phil-left {
        .phil-left-inner-top img {
          height: 45vw;
        }
      }
    }
  }

  .why {
    .container {
      width: 100% !important;
      max-width: 100% !important;
    }
    .row {
      padding: 5vw;
      .why-text {
        padding: 1rem;
        padding-bottom: 0;

        ul {
          padding-left: 3rem;
          li {
            padding: 0 1rem;
            font-size: 1em;
          }
        }
      }
    }
  }
  footer .associated-img img {
    margin: 0;
  }

  .about {
    .about-right {
      .about-right-below {
        img {
          width: 100%;
          height: 225px;
        }
      }
    }
  }
  .main-inner-page {
    .main-inner-page-top {
      .main-inner-page-top-item {
        .main-inner-page-top-item-img {
          img {
            width: 60px;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
    // .related-trip {
    //   .related-trip-img-item {
    //     a {
    //       font-size: 1rem;
    //     }
    //   }
    // }
  }
}
@media screen and (min-width: 2000px) and (max-width: 2999.98px) {
  body {
    font-size: 32px !important;
  }
  .navbar-collapse {
    padding-bottom: 1rem;
  }

  .philosophy {
    .container {
      width: calc(100vw - 2rem) !important;
      max-width: 95% !important;
    }
    .phil-overlay {
      top: 42vw;
    }
    .phil {
      padding: 5vw 1rem;
      .phil-left {
        .phil-left-inner-top img {
          height: 45vw;
        }
      }
    }
  }

  .why {
    .container {
      width: 100% !important;
      max-width: 100% !important;
    }
    .row {
      padding: 5vw;
      .why-text {
        padding: 1rem;
        padding-bottom: 0;
        .title {
          text-align: center;
        }
        ul {
          padding-left: 5em;
          li {
            padding: 0 1rem;
            font-size: 1em;
          }
        }
      }
    }
  }
  footer {
    .container {
      width: calc(100vw - 2rem) !important;
      max-width: 95% !important;
    }
    .associated-img {
      img {
        margin: 0;
      }
    }
  }

  .about {
    .container {
      width: calc(100vw - 2rem) !important;
      max-width: 90% !important;
    }
    .about-right {
      p {
        color: #484848;
        text-align: justify;
        line-height: inherit;
      }
    }
    .about-left img {
      border-radius: 25px;
      width: 100%;
      height: 40vw;
      object-fit: cover;
    }
  }
  .main-inner-page {
    .main-inner-page-top {
      .main-inner-page-top-item {
        .main-inner-page-top-item-img {
          img {
            width: 100px;
            height: auto;
          }
        }
      }
    }
    .main-inner-page-bottom {
      .nav-item {
        padding: 0;
        .nav-link {
          padding: 0.5rem 0.5rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
